import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { SocialButton } from '../../chakra/SocialButton'

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = {
  config,
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
  colors: {
    gray: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6b7280',
      700: '#191919',
      800: '#101010',
      900: '#111827',
    },
    red: {
      500: '#f9004d',
      600: '#f61b10',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('white', 'gray.800')(props),
      },
    }),
  },
  components: {
    SocialButton,
    FormError: {
      baseStyle: {
        text: {
          color: 'red.500',
        },
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'gray.500',
        errorBorderColor: 'red.500',
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'gray.500',
        errorBorderColor: 'red.500',
      },
      baseStyle: {
        field: {
          _invalid: {
            // borderColor: 'red !important',
            // boxShadow: '0 0 0 1px red !important',
            // background: 'red',
          },
        },
      },
      sizes: {
        md: {
          field: {
            py: 6,
          },
        },
      },
    },
  },
}

export default extendTheme(theme)
