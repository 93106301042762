export const SocialButton = {
  baseStyle: {
    color: 'gray.400',
    borderRadius: '100%',
    borderColor: 'gray.400',
    borderWidth: '2px',
    transitionProperty: 'all',
    transitionDuration: '.4s',
    transitionTimingFunction: 'ease-in-out',
    _hover: {
      background: 'red.500',
      borderColor: 'red.500',
      color: 'white',
      transform: 'translateY(-4px)',
    },
  },
  sizes: {},
  variants: {
    header: {
      border: 'none',
    },
  },
  defaultProps: {},
}
